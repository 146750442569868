import { Route, Redirect} from 'react-router-dom';
import Items from "../pages/Items/items.jsx";
import Cart from "../pages/Cart/cart.jsx";
import Transactions from "../pages/Transactions/transactions.jsx";
import Account from "../pages/Account/account.jsx";

const PrivateRoutes = () => {
  const accessToken = localStorage.getItem('access token');
  const isAuthenticated = accessToken !== null;


  if (!isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Route path="/Items" component={Items} />
      <Route path="/Cart" component={Cart} />
      <Route path="/Transactions" component={Transactions} />
      <Route path="/Account" component={Account} />
    </>
  );
};


export default PrivateRoutes;