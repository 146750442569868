import React from "react";
import { Redirect, Route } from 'react-router-dom';
import { IonReactRouter } from '@ionic/react-router';
import { IonApp, IonRouterOutlet } from '@ionic/react';
//without private routes
import Login from "../pages/Login/Login"
import Registration from "../pages/Registration/registration.jsx";

//with private routes
import Items from "../pages/Items/items.jsx";
import Cart from "../pages/Cart/cart.jsx";
import Transactions from "../pages/Transactions/transactions.jsx";
import Account from "../pages/Account/account.jsx";
import Reset1 from "../pages/Reset Password/Reset Password 1/reset1.jsx";
import Reset2 from "../pages/Reset Password/Reset Password 2/reset2.jsx";

//for private routes
import { AuthProvider } from "../context/Authcontext";
import PrivateRoutes from "../private routes/PrivateRoutes";


const Routes = () => (
    <IonApp>
      <AuthProvider>
      <IonReactRouter>
        <IonRouterOutlet>
          <Route exact path="/">
            <Redirect to="/Login" />
          </Route>

          <Route exact path="/Login">
            <Login />
          </Route>

          <Route exact path="/Signup">
            <Registration />
          </Route>

          <Route exact path="/ResetPass">
            <Reset1 />
          </Route>

          <Route exact path="/password/reset/confirm/:uid/:token">
            <Reset2 />
          </Route>

          <PrivateRoutes>
          <Route path="/Items" component={Items}/>
          <Route path="/Cart" component={Cart}/>
          <Route path="/Transactions" component={Transactions}/>
          <Route path="/Account" component={Account}/>
          </PrivateRoutes>
          
        </IonRouterOutlet>
      </IonReactRouter>
      </AuthProvider>
    </IonApp>
  );
  
  export default Routes;