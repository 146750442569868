import {
  IonLabel,
  IonCol,
  IonGrid,
  IonRow,
  IonButton,
  IonInput,
  IonText,
  IonPopover,
  IonSpinner,
} from "@ionic/react";
import "./registration.css";
import axios from "axios";
import configs from "../../configs";
import { useState, useEffect } from "react";
import Logo from "../../assets/gm_logo.png";
import bg from "../../assets/bg.jpg";

import i18n from "../../Translations/i18nConfigs";

const Login = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [data, setData] = useState({
    email: "",
    username: "",
    password1: "",
    password2: "",
    first_name: "",
    last_name: "",
    mob_number: "",
    business_name: "",
    business_address: "",
  });

  console.log(data);

  const [showPopover, setShowPopover] = useState(false);
  const [showPopover2, setShowPopover2] = useState(false);
  const [Validations, setValidations] = useState([]);

  async function onRegister(event) {
    event.preventDefault();
    setSpinner(true);
    axios
      .post(`${configs.base_url}${configs.api_url}register/`, data)
      .then((response) => {
        setSpinner(false);
        setShowPopover(true);

        setTimeout(() => {
          window.location.assign("/login");
        }, 2000);
      })
      .catch((error) => {
        setValidations(error.response.data);
        setShowPopover2(true);
        setSpinner(false);
        console.log(error);
      });
  }
  return (
    <>
      <IonGrid style={{ padding: "0px" }}>
        <IonRow
          className="secondCol"
          size-md="6"
          style={{
            height: `${isSmallScreen ? "20vh" : "0vh"}`,
          }}
        ></IonRow>
        <IonRow
          style={{
            display: "flex",
            flexWrap: "wrap",
            overflow: `${isSmallScreen ? "scroll" : "hidden"}`,
          }}
        >
          <IonCol
            size="12"
            size-md="6"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: `${isSmallScreen ? "80vh" : "100vh"}`,
              zIndex: "1",
            }}
          >
            <IonRow style={{ width: "80%", display: "block", margin: "auto" }}>
              <img
                src={Logo}
                alt="logo"
                style={{
                  width: `${isSmallScreen ? "50%" : "30%"}`,
                  marginTop: `${isSmallScreen ? "20%" : "0%"}`,
                }}
              ></img>
              <h3 className="txtLogin">{i18n.t("signup.title")}</h3>
              <form onSubmit={onRegister}>
                <IonRow>
                  <IonCol sizeLg="6" size="12">
                    <IonLabel className="txtLabel">
                      {i18n.t("signup.email")}
                    </IonLabel>
                    <IonInput
                      required
                      type="text"
                      className="txtField"
                      placeholder={i18n.t("signup.emailPlaceholder")}
                      id="emailtxt"
                      name="email"
                      autocomplete="email"
                      value={data.email}
                      onIonChange={(event) =>
                        setData({
                          ...data,
                          email: event.detail.value,
                        })
                      }
                    ></IonInput>
                  </IonCol>
                  <IonCol sizeLg="6" size="12">
                    <IonLabel className="txtLabel">
                      {i18n.t("signup.username")}
                    </IonLabel>
                    <IonInput
                      required
                      type="text"
                      className="txtField"
                      placeholder={i18n.t("signup.usernamePlaceholder")}
                      id="userTxt"
                      name="username"
                      autocomplete="username"
                      value={data.username}
                      onIonChange={(event) =>
                        setData({
                          ...data,
                          username: event.detail.value,
                        })
                      }
                    ></IonInput>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol sizeLg="6" size="12">
                    <IonLabel className="txtLabel">
                      {i18n.t("signup.password")}
                    </IonLabel>
                    <IonInput
                      required
                      type="password"
                      inputMode="numeric"
                      className="txtField"
                      id="passwordtxt"
                      placeholder={i18n.t("signup.passwordPlaceholder")}
                      name="password"
                      autocomplete="current-password"
                      value={data.password1}
                      onIonChange={(event) => {
                        const value = event.detail.value.replace(/\D/g, ""); // Remove non-numeric characters
                        setData({
                          ...data,
                          password1: value,
                        });
                      }}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    ></IonInput>
                  </IonCol>
                  <IonCol sizeLg="6" size="12">
                    <IonLabel className="txtLabel">
                      {i18n.t("signup.confirmPass")}
                    </IonLabel>
                    <IonInput
                      required
                      type="password"
                      inputMode="numeric"
                      className="txtField"
                      id="passwordtxt"
                      placeholder={i18n.t("signup.confirmPass")}
                      name="password"
                      autocomplete="new-password"
                      value={data.password2}
                      onIonChange={(event) => {
                        const value = event.detail.value.replace(/\D/g, ""); // Remove non-numeric characters
                        setData({
                          ...data,
                          password2: value,
                        });
                      }}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    ></IonInput>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol sizeLg="6" size="12">
                    <IonLabel className="txtLabel">
                      {i18n.t("signup.firstName")}
                    </IonLabel>
                    <IonInput
                      required
                      type="text"
                      className="txtField"
                      id="passwordtxt"
                      placeholder={i18n.t("signup.firstNamePlaceholder")}
                      name="firstName"
                      autocomplete="given-name"
                      value={data.first_name}
                      onIonChange={(event) =>
                        setData({
                          ...data,
                          first_name: event.detail.value,
                        })
                      }
                    ></IonInput>
                  </IonCol>
                  <IonCol sizeLg="6" size="12">
                    <IonLabel className="txtLabel">
                      {i18n.t("signup.lastName")}
                    </IonLabel>
                    <IonInput
                      required
                      type="text"
                      className="txtField"
                      id="passwordtxt"
                      placeholder={i18n.t("signup.lastNamePlaceholder")}
                      name="lastName"
                      autocomplete="family-name"
                      value={data.last_name}
                      onIonChange={(event) =>
                        setData({
                          ...data,
                          last_name: event.detail.value,
                        })
                      }
                    ></IonInput>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol sizeLg="6" size="12">
                    <IonLabel className="txtLabel">
                      {i18n.t("signup.mobileNumber")}
                    </IonLabel>
                    <IonInput
                      required
                      type="text"
                      className="txtField"
                      id="mobileTxt"
                      placeholder={i18n.t("signup.mobileNumberPlaceholder")}
                      name="mobileNumber"
                      autocomplete="cc-number"
                      value={data.mob_number}
                      onIonChange={(event) =>
                        setData({
                          ...data,
                          mob_number: event.detail.value,
                        })
                      }
                    ></IonInput>
                  </IonCol>
                  <IonCol sizeLg="6" size="12">
                    <IonLabel className="txtLabel">
                      {i18n.t("signup.businessName")}
                    </IonLabel>
                    <IonInput
                      required
                      type="text"
                      className="txtField"
                      id="businessNameTxt"
                      placeholder={i18n.t("signup.businessNamePlaceholder")}
                      name="businessName"
                      autocomplete="additional-name"
                      value={data.business_name}
                      onIonChange={(event) =>
                        setData({
                          ...data,
                          business_name: event.detail.value,
                        })
                      }
                    ></IonInput>
                  </IonCol>

                  <IonCol size="12">
                    <IonLabel className="txtLabel">
                      {i18n.t("signup.businessAddress")}
                    </IonLabel>
                    <IonInput
                      required
                      type="text"
                      className="txtField"
                      id="businessNameTxt"
                      placeholder={i18n.t("signup.businessAddressPlaceholder")}
                      name="businessName"
                      autocomplete="address-line1"
                      value={data.business_address}
                      onIonChange={(event) =>
                        setData({
                          ...data,
                          business_address: event.detail.value,
                        })
                      }
                    ></IonInput>
                  </IonCol>
                </IonRow>
                {!spinner && (
                  <IonButton
                    type="submit"
                    expand="block"
                    class="btnLogin"
                    style={{
                      width: "100%",
                      height: "50px",
                      textTransform: "none",
                      marginTop: "20px",
                    }}
                  >
                    {i18n.t("signup.title")}
                  </IonButton>
                )}
                {spinner && (
                  <IonButton
                    type="submit"
                    expand="block"
                    class="btnLogin"
                    disabled
                    style={{
                      width: "100%",
                      height: "50px",
                      textTransform: "none",
                      marginTop: "20px",
                    }}
                  >
                    <IonSpinner></IonSpinner>
                  </IonButton>
                )}
                <IonPopover
                  isOpen={showPopover}
                  onDidDismiss={() => setShowPopover(false)}
                >
                  <ion-content id="confirmation" class="ion-padding">
                    {i18n.t("signup.regSuccess")}
                    <br></br> {i18n.t("signup.wait")}
                  </ion-content>
                </IonPopover>

                <IonPopover
                  isOpen={showPopover2}
                  onDidDismiss={() => setShowPopover2(false)}
                >
                  <ion-content id="confirmation" class="ion-padding">
                    {Validations &&
                      Object.keys(Validations).map((fieldName, index) => (
                        <p id="confirmation" key={index.toString()}>
                          {`${Validations[fieldName].join(", ")}`}
                        </p>
                      ))}
                  </ion-content>
                </IonPopover>
              </form>

              <IonText
                style={{
                  display: "block",
                  margin: "auto",
                  marginTop: "30px",
                  fontSize: "14px",
                  textAlign: "center",
                  marginBottom: "30px",
                }}
              >
                {i18n.t("signup.haveAnAccount")}
                <a href="/Login">{i18n.t("signup.login")}</a>
              </IonText>
            </IonRow>
          </IonCol>
          <IonCol
            className="secondCol"
            size-md="6"
            style={{
              height: `${isSmallScreen ? "0vh" : "100vh"}`,
              position: `${isSmallScreen ? "absolute" : "relative"}`,
              top: "0",
              left: "0",
              width: "100%",
              zIndex: "1",
              padding: "0px",
            }}
          >
            <img
              src={bg}
              style={{ height: `${isSmallScreen ? "0%" : "100vh"}` }}
            ></img>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default Login;
