import {
  IonButton,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonMenuButton,
  IonPage,
  IonToolbar,
  IonSearchbar,
  IonSplitPane,
  IonCard,
  IonItem,
  IonLabel,
  IonSelectOption,
  IonSelect,
  IonAvatar,
  IonInput,
  IonPopover,
  IonSpinner,
} from "@ionic/react";

import "./account.css";
import { useState, useEffect } from "react";
import axios from "axios";
import configs from "../../configs/index";
import Menu from "../../sidebar/sidebar";
import { useLocation } from "react-router-dom";
//for accessToken
import { useContext } from "react";
import AuthContext from "../../context/Authcontext";

import i18n from "../../Translations/i18nConfigs";

function Account() {

  const { accesstoken } = useContext(AuthContext);

  const location = useLocation();

  const [hasChanges, setHasChanges] = useState(false);

  const setInfo1 = (newInfo) => {
    setInfo1({ ...info, ...newInfo });
    setHasChanges(true);
  };

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  const [infos, setInfos] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [spinner2, setSpinner2] = useState(false);

  const [profile_photo, setProfile_photo] = useState(null);
  const [profile, setProfile] = useState(null);

  const [showPopover, setShowPopover] = useState(false);
  const [showPopover2, setShowPopover2] = useState(false);

  //form for basic info
  const [info, setInfo] = useState({
    first_name: infos?.first_name,
    last_name: infos?.last_name,
    mob_number: infos?.mob_number,
    business_name: infos?.business_name,
    business_address: infos?.business_address,
    username: infos?.username
  });

  //form for password change
  const [pass, setPass] = useState({
    new_password1: "",
    new_password2: "",
    old_password: "",
  });

  async function getInfo() {
    axios
      .get(`${configs.base_url}${configs.api_url}userdetail/`, {
        headers: {
          Authorization: `Bearer ${accesstoken}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setInfos(response?.data);
        setInfo({
          first_name: response.data.first_name || "",
          last_name: response.data.last_name || "",
          mob_number: response.data.mob_number || "",
          business_name: response.data.business_name || "",
          business_address: response.data.business_address || "",
          username: response.data.username || "",
        });
        localStorage.setItem("profile_photo", response.data.profile_photo);
        setProfile_photo(response.data.profile_photo);

        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    getInfo();
  }, []);

  useEffect(() => {
    const isInfoChanged =
      info.first_name !== infos.first_name ||
      info.last_name !== infos.last_name ||
      info.mob_number !== infos.mob_number ||
      info.business_name !== infos.business_name ||
      info.business_address !== infos.business_address ||
      info.username !== infos.username ||
      profile !== null ||
      // Add more fields if needed
      false; // Change to true if any field is different
  
    setHasChanges(isInfoChanged);
  }, [info, infos, profile]);

  //function for putting the image on the avatar
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setProfile(file);

    const reader = new FileReader();
    reader.onload = () => {
      setProfile_photo(reader.result);
    };
    reader.readAsDataURL(file);
  };

  //formData
  const formData = new FormData();
  formData.append("first_name", info.first_name);
  formData.append("last_name", info.last_name);
  formData.append("mob_number", info.mob_number);
  formData.append("business_name", info.business_name);
  formData.append("business_address", info.business_address);
  if (profile) {
    formData.append("profile_photo", profile);
  }

  async function changeInfo(event) {
    event.preventDefault();
    setSpinner(true);
    axios
      .put(`${configs.base_url}${configs.api_url}userdetail/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accesstoken}`,
        },
      })
      .then((response) => {
        setSpinner(false);
        setMessage(i18n.t('account.successfullyEdited'));
        setShowPopover(true);
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
        setSpinner(false);
        setMessage(i18n.t('account.failedToEdit'));
        error.response.request.status === 400
          ? setErrorMessage(error.response.data)
          : setShowPopover(true);
      });
  }

  async function changePass(event) {
    event.preventDefault();
    setSpinner2(true);
    axios
      .post(`${configs.base_url}${configs.api_url}password/change/`, pass, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accesstoken}`,
        },
      })
      .then((response) => {
        setSpinner2(false);
        setMessage(i18n.t('account.successfullyChangedPassword'));
        setShowPopover(true);
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
        setSpinner2(false);
        setMessage(i18n.t('account.failedToChangePassword'));
        error.response.request.status === 400
          ? setErrorMessage(error.response.data)
          : setShowPopover(true);
      });
  }

  async function deleteAcc(event) {
    event.preventDefault();
    axios
      .post(
        `${configs.base_url}${configs.api_url}users/delete/`,
        {
          perform_removal: true
        },
        {
          headers: {
            Authorization: `Bearer ${accesstoken}`,
          },
        }
      )
      .then((response) => {
        setMessage(i18n.t('account.successfullyDeletedAccount'));
        setShowPopover(true);

        setTimeout(() => {
          window.location.assign("/login");
        }, 2000);
        
      })

      .catch((error) => {
        setMessage(error.response.data);
        setShowPopover(true);
      });
  }

  

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <IonPopover
        isOpen={showPopover}
        onDidDismiss={() => setShowPopover(false)}
      >
        <ion-content class="ion-padding">{message}</ion-content>
      </IonPopover>

      <IonPopover
        isOpen={showPopover2}
        onDidDismiss={() => setShowPopover2(false)}
      >
        <ion-content class="ion-padding">
        {i18n.t('account.deleteAccountconfirmation')}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <IonButton fill="clear" onClick={() => setShowPopover2(false)}>
            {i18n.t('account.cancel')}
            </IonButton>
            <IonButton fill="clear" onClick={deleteAcc}> {i18n.t('account.confirm')}</IonButton>
          </div>
        </ion-content>
      </IonPopover>

      <IonSplitPane when="sm" contentId="main">
        {/* menu --1st column */}
        <Menu location={location} />

        {/* --2nd column */}
        <div className="ion-page" id="main">
          <IonPage id="main-content">
            <IonContent className="ion-padding">
              {/* menu button that shows only on small screen */}
              <IonToolbar className="toolbar">
                <IonButton fill="clear" slot="start">
                  <IonMenuButton style={{ color: "black" }}></IonMenuButton>
                </IonButton>
              </IonToolbar>
              <IonGrid
                style={{
                  width: `${isSmallScreen ? "100%" : "80%"}`,
                  paddingBottom: "20px",
                }}
              >
                <IonRow>
                  <IonCol size="6" style={{ margin: "auto", display: "block" }}>
                    <h3 id="accTitle"> {i18n.t('account.title')}</h3>
                  </IonCol>
                  <IonCol size="6" style={{ margin: "auto", display: "block" }}>
                    {!spinner && (
                      <IonButton
                        className="accBtn"
                        style={{
                          float: "right",
                          textTransform: "none",
                          width: "100px",
                        }}
                        onClick={changeInfo}
                        disabled={!hasChanges} 
                      >
                         {i18n.t('account.save')}
                      </IonButton>
                    )}
                    {spinner && (
                      <IonButton
                        className="accBtn"
                        style={{
                          float: "right",
                          textTransform: "none",
                          width: "100px",
                        }}
                        disabled
                      >
                        <IonSpinner></IonSpinner>
                      </IonButton>
                    )}
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonAvatar
                    style={{
                      margin: "auto",
                      display: "block",
                      marginTop: "20px",
                      width: "80px",
                      height: "80px",
                    }}
                  >
                    <img
                      alt="Profile"
                      src={
                        profile_photo
                          ? profile_photo
                          : "https://ionicframework.com/docs/img/demos/avatar.svg"
                      }
                    />
                  </IonAvatar>
                </IonRow>

                <IonRow>
                  <input
                    type="file"
                    id="profile-pic-input"
                    accept=".jpg,.jpeg,.png"
                    style={{ display: "none" }}
                    onChange={handleFileInputChange}
                  />

                  <IonButton
                    className="accBtn"
                    style={{
                      margin: "auto",
                      display: "block",
                      marginTop: "20px",
                      textTransform: "none",
                    }}
                    onClick={() =>
                      document.getElementById("profile-pic-input").click()
                    }
                  >
                     {i18n.t('account.uploadPhoto')}
                  </IonButton>
                  <IonCol size="12" style={{ textAlign: "center" }}>
                    <p className="label" style={{ color: "red" }}>
                      <b>{errorMessage?.profile_photo}</b>
                    </p>
                  </IonCol>
                </IonRow>

                <IonRow style={{ marginTop: "20px" }}>
                  <p
                    className="accName"
                    style={{ margin: "auto", display: "block" }}
                  >
                    {infos?.first_name} {infos?.last_name}
                  </p>
                </IonRow>
                <IonRow>
                  <p
                    className="accEmail"
                    style={{ margin: "auto", display: "block" }}
                  >
                    {infos?.email}
                  </p>
                </IonRow>

                <IonRow>
                  <p
                    className="accEmail"
                    style={{ margin: "auto", display: "block" }}
                  >
                    {i18n.t('account.customerNumber')}: {infos?.customer_no}
                  </p>
                </IonRow>

                <form>
                  <IonGrid
                    size="12"
                    sizeLg="6"
                    style={{
                      width: `${isSmallScreen ? "100%" : "40%"}`,
                      marginBottom: 60,
                    }}
                  >
                    <IonRow>
                      <IonCol sizeLg="6" size="12">
                        <p className="label" style={{ color: "red" }}>
                          <b>{errorMessage?.first_name}</b>
                        </p>
                        <IonLabel className="label">{i18n.t('account.firstName')}</IonLabel>
                        <IonInput
                          style={{
                            background: "white",
                            border: "1px solid #BEBEBE",
                            marginTop: 5,
                          }}
                          type="text"
                          onIonChange={(event) =>
                            setInfo({ ...info, first_name: event.target.value })
                          }
                          required
                          value={info.first_name}
                        />
                      </IonCol>
                      <IonCol sizeLg="6" size="12">
                        <p className="label" style={{ color: "red" }}>
                          <b>{errorMessage?.last_name}</b>
                        </p>
                        <IonLabel className="label">{i18n.t('account.lastName')}</IonLabel>
                        <IonInput
                          style={{
                            background: "white",
                            border: "1px solid #BEBEBE",
                            marginTop: 5,
                          }}
                          type="text"
                          onIonChange={(event) =>
                            setInfo({ ...info, last_name: event.target.value })
                          }
                          required
                          value={info.last_name}
                        />
                      </IonCol>

                      <IonCol sizeLg="6" size="12">
                        <p className="label" style={{ color: "red" }}>
                          <b>{errorMessage?.mob_number}</b>
                        </p>
                        <IonLabel className="label">{i18n.t('account.mobileNumber')}</IonLabel>
                        <IonInput
                          style={{
                            background: "white",
                            border: "1px solid #BEBEBE",
                            marginTop: 5,
                          }}
                          type="text"
                          onIonChange={(event) =>
                            setInfo({ ...info, mob_number: event.target.value })
                          }
                          required
                          value={info.mob_number}
                        />
                      </IonCol>

                      <IonCol sizeLg="6" size="12">
                        <p className="label" style={{ color: "red" }}>
                          <b>{errorMessage?.username}</b>
                        </p>
                        <IonLabel className="label">{i18n.t('account.businessName')}</IonLabel>
                        <IonInput
                          style={{
                            background: "white",
                            border: "1px solid #BEBEBE",
                            marginTop: 5,
                          }}
                          type="text"
                          onIonChange={(event) =>
                            setInfo({
                              ...info,
                              business_name: event.target.value,
                            })
                          }
                          required
                          value={info.business_name}
                        />
                      </IonCol>
                      <IonCol size="12">
                        <p className="label" style={{ color: "red" }}>
                          <b>{errorMessage?.username}</b>
                        </p>
                        <IonLabel className="label">{i18n.t('account.username')}</IonLabel>
                        <IonInput
                          style={{
                            background: "white",
                            border: "1px solid #BEBEBE",
                            marginTop: 5,
                          }}
                          type="text"
                          onIonChange={(event) =>
                            setInfo({
                              ...info,
                              username: event.target.value,
                            })
                          }
                          required
                          disabled
                          value={info.username}
                        />
                      </IonCol>
                      <IonCol size="12">
                        <p className="label" style={{ color: "red" }}>
                          <b>{errorMessage?.business_address}</b>
                        </p>
                        <IonLabel className="label">{i18n.t('account.businessAddress')}</IonLabel>
                        <IonInput
                          style={{
                            background: "white",
                            border: "1px solid #BEBEBE",
                            marginTop: 5,
                          }}
                          type="text"
                          onIonChange={(event) =>
                            setInfo({
                              ...info,
                              business_address: event.target.value,
                            })
                          }
                          required
                          value={info.business_address}
                        />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </form>

                <IonRow
                  style={{
                    border: "1px solid rgba(150, 150, 150, 0.32)",
                    width: "100%",
                    margin: "0 auto",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 40,
                  }}
                ></IonRow>

                <IonRow style={{ marginBottom: 20 }}>
                  <p className="changePass" style={{ margin: "auto" }}>
                  {i18n.t('account.changePassword')}
                  </p>
                </IonRow>
                <form onSubmit={changePass}>
                  <IonGrid
                    size="12"
                    sizeLg="4"
                    style={{
                      width: `${isSmallScreen ? "100%" : "30%"}`,
                      marginBottom: 60,
                    }}
                  >
                    <IonRow>
                      <IonCol size="12">
                        <p className="label" style={{ color: "red" }}>
                          <b>{errorMessage?.old_password}</b>
                        </p>
                        <IonLabel className="label"> {i18n.t('account.oldPassword')}</IonLabel>
                        <IonInput
                          type="password"
                          style={{
                            background: "white",
                            border: "1px solid #BEBEBE",
                            marginTop: 5,
                          }}
                          onIonChange={(event) =>
                            setPass({
                              ...pass,
                              old_password: event.target.value,
                            })
                          }
                          required
                        />
                      </IonCol>
                      <IonCol size="12">
                        <p className="label" style={{ color: "red" }}>
                          <b>{errorMessage?.new_password1}</b>
                        </p>
                        <IonLabel className="label"> {i18n.t('account.newPassword')}</IonLabel>
                        <IonInput
                          type="password"
                          style={{
                            background: "white",
                            border: "1px solid #BEBEBE",
                            marginTop: 5,
                          }}
                          onIonChange={(event) =>
                            setPass({
                              ...pass,
                              new_password1: event.target.value,
                            })
                          }
                          required
                        />
                      </IonCol>

                      <IonCol size="12">
                        <p className="label" style={{ color: "red" }}>
                          <b>{errorMessage?.new_password2}</b>
                        </p>
                        <IonLabel className="label">
                        {i18n.t('account.confirmNewPassword')}
                        </IonLabel>
                        <IonInput
                          type="password"
                          style={{
                            background: "white",
                            border: "1px solid #BEBEBE",
                            marginTop: 5,
                          }}
                          onIonChange={(event) =>
                            setPass({
                              ...pass,
                              new_password2: event.target.value,
                            })
                          }
                          required
                        />
                      </IonCol>

                      <IonCol sizeLg="6" size="12" style={{ margin: "auto" }}>
                        {!spinner2 && (
                          <IonButton
                            className="accBtn"
                            style={{
                              margin: "auto",
                              display: "block",
                              marginTop: "20px",
                              textTransform: "none",
                            }}
                            type="submit"
                          >
                             {i18n.t('account.update')}
                          </IonButton>
                        )}
                        {spinner2 && (
                          <IonButton
                            className="accBtn"
                            style={{
                              margin: "auto",
                              display: "block",
                              marginTop: "20px",
                              textTransform: "none",
                            }}
                            disabled
                            type="submit"
                          >
                            <IonSpinner></IonSpinner>
                          </IonButton>
                        )}
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <IonButton
                      color="danger"
                      style={{ textTransform: "none" }}
                      onClick={() => setShowPopover2(true)}
                    >
                       {i18n.t('account.deleteAccount')}
                    </IonButton>
                  </div>
                </form>
              </IonGrid>
            </IonContent>
          </IonPage>
        </div>
      </IonSplitPane>
    </>
  );
}

export default Account;
