import {
  IonButton,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonMenuButton,
  IonPage,
  IonToolbar,
  IonSearchbar,
  IonSplitPane,
  IonCard,
  IonText,
  IonIcon,
  IonPopover,
  IonModal,
  IonHeader,
  IonButtons,
  IonTitle,
  IonInput,
  IonItem,
} from "@ionic/react";

import { addCircle, removeCircle } from "ionicons/icons";
import { useState, useEffect } from "react";
import axios from "axios";
import configs from "../../configs/index";
import "./items.css";
import Menu from "../../sidebar/sidebar";
import { useLocation } from "react-router-dom";
import Onsale from "../../assets/Onsale.png";
//for accessToken
import { useContext } from "react";
import AuthContext from "../../context/Authcontext";

import i18n from "../../Translations/i18nConfigs";

function Items() {
  const { accesstoken } = useContext(AuthContext);

  const [showPopover, setShowPopover] = useState(false);
  const [showPopover2, setShowPopover2] = useState(false);
  const [showPopover3, setShowPopover3] = useState(false);

  const [isActive, setIsActive] = useState("All");

  const [detailedProducts, setDetailedProducts] = useState();

  //validation
  const [errorValidation, setErrorValidation] = useState();

  const [products, setProducts] = useState([]);
  const [productsFiltered, setProductsFiltered] = useState([]);
  const [saleProducts, setSaleProducts] = useState([]);
  const [counts, setCounts] = useState({});

  const [category, setCategory] = useState([]);

  const [categoryProducts, setCategoryProducts] = useState({});

  const handleInputChange = (e, productId) => {
    const newCounts = { ...counts };
    newCounts[productId] = e.target.value;
    setCounts(newCounts);
  };

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //modal functions
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();

  const handleDecrement = (id) => {
    const updatedCounts = { ...counts };
    updatedCounts[id] = Math.max(Number(updatedCounts[id]) - 1, 0);
    setCounts(updatedCounts);
  };

  const handleIncrement = (id) => {
    const updatedCounts = { ...counts };
    updatedCounts[id] = Math.min(Number(updatedCounts[id]) + 1, 100);
    setCounts(updatedCounts);
  };

  const handleClearCount = (id) => {
    setCounts((prevCounts) => ({
      ...prevCounts,
      [id]: 0,
    }));
  };

  async function getProducts() {
    axios
      .get(`${configs.base_url}${configs.api_url}products/`, {
        headers: {
          Authorization: `Bearer ${accesstoken}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        const initialCounts = {};
        response.data.forEach((product) => {
          initialCounts[product.id] = 0;
        });
        setCounts(initialCounts);
        setProducts(response.data);
        setSaleProducts(response.data);
        setIsActive("All");
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    getProducts();
  }, []);

  async function getCategories() {
    axios
      .get(`${configs.base_url}${configs.api_url}category/list/`, {
        headers: {
          Authorization: `Bearer ${accesstoken}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setCategory(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    getCategories();
    getProducts();
  }, []);

  async function handleAddCart(id, quantity) {
    axios
      .post(
        `${configs.base_url}${configs.api_url}cart/add/`,
        {
          item: id,
          quantity: quantity,
        },
        {
          headers: {
            Authorization: `Bearer ${accesstoken}`,
          },
        }
      )
      .then((response) => {
        handleClearCount(id);
        setShowPopover(true);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          setErrorValidation(error.response.data.error);
          setShowPopover2(true);
        } else {
          setErrorValidation(i18n.t("items.addCartFailed"));
          setShowPopover3(true);
        }
      });
  }

  async function getDetailedProduct(id) {
    setIsOpen(false);
    axios
      .get(`${configs.base_url}${configs.api_url}products/${id}/`, {
        headers: {
          Authorization: `Bearer ${accesstoken}`,
        },
      })
      .then((response) => {
        setDetailedProducts(response.data);
        setIsOpen(true);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  //filter function

  async function filter(product) {
    axios
      .get(`${configs.base_url}${configs.api_url}products/?search=${product}`, {
        headers: {
          Authorization: `Bearer ${accesstoken}`,
        },
      })
      .then((response) => {
        setProductsFiltered(response.data);
        setIsActive(product);
        setSearchTerm("");
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const [searchTerm, setSearchTerm] = useState("");
  const [searchProduct, setSearchProduct] = useState("");
  const [noProduct, setNoProduct] = useState(false);

  //style for the button filters
  const activeStyle = {
    color: "white",
    textTransform: "none",
    marginRight: "10px",
    fontSize: "16px",
  };
  const inactiveStyle = {
    color: "black",
    textTransform: "none",
    marginRight: "10px",
    fontSize: "16px",
  };

  return (
    <>
      <IonSplitPane when="sm" contentId="main">
        {/* menu --1st column */}
        <Menu location={location} />

        {/* --2nd column */}
        <div className="ion-page" id="main">
          <IonPage id="main-content">
            <IonContent className="ion-padding">
              {/* menu button that shows only on small screen */}
              <IonToolbar className="toolbar">
                <IonMenuButton
                  style={{ color: "black", float: "left" }}
                ></IonMenuButton>
              </IonToolbar>
              <IonGrid>
                <IonModal
                  isOpen={isOpen}
                  style={{
                    "--height": `${isSmallScreen ? "100%" : "50%"}`,
                  }}
                >
                  <IonHeader>
                    <IonToolbar>
                      <IonTitle>{detailedProducts?.name}</IonTitle>
                      <IonButtons slot="end">
                        <IonButton onClick={() => setIsOpen(false)}>
                          {i18n.t("items.close")}
                        </IonButton>
                      </IonButtons>
                    </IonToolbar>
                  </IonHeader>
                  <IonContent>
                    <IonRow style={{ padding: "30px" }}>
                      <IonCol size="12" sizeLg="4">
                        {detailedProducts?.image ? (
                          <img
                            alt={detailedProducts.name}
                            style={{ width: "100%" }}
                            src={detailedProducts.image}
                          />
                        ) : (
                          <IonText>{i18n.t("items.imageNotAvailable")}</IonText>
                        )}
                      </IonCol>
                      <IonCol size="12" sizeLg="8">
                        <p className="productText">
                          {i18n.t("items.productId")}:{" "}
                          {detailedProducts?.product_id}
                        </p>
                        <p className="productText">
                          {i18n.t("items.by")}:{" "}
                          {detailedProducts?.owner.company}
                        </p>
                        <p className="productText">
                          {/* {i18n.t("items.price")}
                          {detailedProducts?.price} Euro */}
                        </p>

                        <IonRow
                          style={{
                            width: `${isSmallScreen ? "100%" : "50%"}`,
                            margin: "auto",
                          }}
                        >
                          <IonCol size="4">
                            <IonButton
                              onClick={() =>
                                handleDecrement(detailedProducts?.id)
                              }
                              fill="clear"
                              size="default"
                              style={{ "--background": "transparent" }}
                            >
                              <IonIcon
                                style={{ cursor: "pointer", color: "#980101" }}
                                icon={removeCircle}
                                size="large"
                              />
                            </IonButton>
                          </IonCol>

                          <IonCol
                            size="4"
                            class="ion-text-center"
                            style={{ display: "block", margin: "auto" }}
                          >
                            <IonItem>
                              <IonInput
                                type="text"
                                value={counts[detailedProducts?.id]}
                                onInput={(e) => {
                                  let sanitizedValue = e.target.value
                                    .replace(/[^0-9]/g, "")
                                    .slice(0, 3);
                                  if (sanitizedValue > 100) {
                                    sanitizedValue = sanitizedValue.slice(0, 2);
                                  }
                                  e.target.value = sanitizedValue;
                                  handleInputChange(e, detailedProducts?.id);
                                }}
                                style={{ textAlign: "center" }}
                              />
                            </IonItem>
                          </IonCol>

                          <IonCol size="4">
                            <IonButton
                              onClick={() =>
                                handleIncrement(detailedProducts?.id)
                              }
                              fill="clear"
                            >
                              <IonIcon
                                style={{ cursor: "pointer", color: "#00650A" }}
                                icon={addCircle}
                                size="large"
                              />
                            </IonButton>
                          </IonCol>
                        </IonRow>

                        <IonButton
                          onClick={() =>
                            handleAddCart(
                              detailedProducts?.id,
                              counts[detailedProducts?.id]
                            )
                          }
                          expand="full"
                          style={{ textTransform: "none", marginTop: 30 }}
                          class="addCartBtn"
                        >
                          {i18n.t("items.addToCart")}
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonContent>
                </IonModal>

                {/* modify flash sales here */}
                <IonRow>
                  <IonCol
                    size="12"
                    sizeLg="6"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <h3 id="title" style={{ textAlign: "left", margin: 0 }}>
                      {i18n.t("items.flashSale")}
                    </h3>
                  </IonCol>
                </IonRow>

                <IonCol style={{ padding: "0px" }}>
                  {/* allows scrolling horizontally */}
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "nowrap",
                      overflowX: "auto",
                    }}
                  >
                    {saleProducts
                      .filter((item) => item.sale === true)
                      .map((item, index) => (
                        <IonCard
                          // onClick={() => getDetailedProduct(item.id)}
                          key={item.id}
                          style={{
                            width: `${isSmallScreen ? "90%" : "30%"}`,
                            marginTop: 20,
                            marginBottom: 10,
                            // cursor: "pointer",
                            flex: "0 0 auto",
                            marginRight: "10px",
                            padding: "0px 10px 10px 10px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}
                        >
                          <img src={Onsale} className="saleSize" />
                          <IonRow
                            style={{
                              height: "30vh",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <IonCol
                              style={{
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {item.image ? (
                                <img
                                  alt={item.name}
                                  style={{
                                    maxWidth: "100%",
                                    maxHeight: "100%",
                                    borderRadius: "10px",
                                    objectFit: "cover", // ensure that the image is not stretched
                                  }}
                                  src={item.image}
                                />
                              ) : (
                                <IonText>Image not available</IonText>
                              )}
                            </IonCol>

                            <IonCol style={{ padding: "5px" }}>
                              <IonRow>
                                <h3
                                  style={{
                                    color: "#303133",
                                    fontSize: 16,
                                  }}
                                >
                                  {item.name}
                                </h3>
                              </IonRow>
                              <IonRow>
                                {item.description !== "null" &&
                                  item.description !== "" && (
                                    <p
                                      style={{
                                        textAlign: "center",
                                        color: "#303133",
                                      }}
                                    >
                                      {item.description}
                                    </p>
                                  )}
                              </IonRow>

                              {/* <IonRow class="ion-justify-content-center">
                                <IonCol
                                  style={{
                                    textAlign: "left",
                                    color: "black",
                                  }}
                                >
                                  {i18n.t("items.price")}
                                </IonCol>
                                <IonCol
                                  style={{
                                    textAlign: "center",
                                    color: "black",
                                  }}
                                >
                                  <div
                                    style={{
                                      borderBottom:
                                        "0.5px solid rgba(48, 49, 51, 0.46)",
                                    }}
                                  >
                                    €{item.price}
                                  </div>
                                </IonCol>
                              </IonRow> */}
                              <IonRow
                                class="ion-justify-content-center"
                                style={{ margin: "auto" }}
                              >
                                <IonCol size="4" sizeLg="4">
                                  {" "}
                                  <IonButton
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      handleDecrement(item.id);
                                    }}
                                    fill="clear"
                                    size={"small"}
                                  >
                                    <IonIcon
                                      slot="icon-only"
                                      icon={removeCircle}
                                      style={{ color: "#980101" }}
                                    ></IonIcon>
                                  </IonButton>
                                </IonCol>
                                <IonCol
                                  size="4"
                                  sizeLg="4"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      borderBottom:
                                        "0.5px solid rgba(48, 49, 51, 0.46)",
                                      width: "100%",
                                    }}
                                  >
                                    <IonInput
                                      style={{
                                        margin: "auto",
                                        textAlign: "center",
                                        border: "none",
                                        outline: "none",
                                        width: "100%",
                                      }}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                      }}
                                      type="text"
                                      value={counts[item.id]}
                                      onIonChange={(e) => {
                                        let sanitizedValue = e.detail.value
                                          .replace(/[^0-9]/g, "")
                                          .slice(0, 3);
                                        if (sanitizedValue > 100) {
                                          sanitizedValue = sanitizedValue.slice(
                                            0,
                                            2
                                          );
                                        }
                                        setCounts({
                                          ...counts,
                                          [item.id]: sanitizedValue,
                                        });
                                      }}
                                    />
                                  </div>
                                </IonCol>
                                <IonCol size="4" sizeLg="4">
                                  {" "}
                                  <IonButton
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      handleIncrement(item.id);
                                    }}
                                    fill="clear"
                                    size={"small"}
                                  >
                                    <IonIcon
                                      slot="icon-only"
                                      icon={addCircle}
                                      style={{ color: "#00650A" }}
                                    ></IonIcon>
                                  </IonButton>
                                </IonCol>
                              </IonRow>
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonButton
                              onClick={(event) => {
                                event.stopPropagation();
                                handleAddCart(item.id, counts[item.id]);
                              }}
                              expand="full"
                              class="addCartBtn"
                              style={{
                                textTransform: "none",
                                width: "100%",
                                display: "block",
                              }}
                            >
                              {i18n.t("items.addToCart")}
                            </IonButton>
                          </IonRow>
                          {/* <p className="priceFont2">{item.price} Euro</p> */}
                        </IonCard>
                      ))}
                  </div>
                </IonCol>
                <IonRow style={{ marginTop: 30 }}>
                  <IonCol
                    size="12"
                    sizeLg="6"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <h3
                      id="title"
                      style={{ textAlign: "left", margin: 0, marginBottom: 10 }}
                    >
                      Items
                    </h3>
                  </IonCol>
                  <IonCol size="12" sizeLg="6" style={{ padding: "0px" }}>
                    <IonSearchbar
                      expand="full"
                      placeholder={i18n.t("items.searchOrder")}
                      value={searchTerm}
                      onIonChange={(e) => setSearchTerm(e.detail.value)}
                    ></IonSearchbar>
                  </IonCol>
                </IonRow>
                <IonRow style={{ marginTop: 20 }}>
                  <IonCol style={{ padding: "0px" }}>
                    {/* allows scrolling horizontally */}
                    <div
                      style={{
                        overflowX: "auto",
                        whiteSpace: "nowrap",
                        height: "max-content",
                      }}
                    >
                      <IonButton
                        onClick={() => {
                          setIsActive("All");
                          getProducts();
                        }}
                        style={isActive === "All" ? activeStyle : inactiveStyle}
                        class={isActive === "All" ? "active" : "inactive"}
                      >
                        {i18n.t("items.all")}
                      </IonButton>

                      {Array.from(
                        new Set(
                          products
                            .filter((product) => !product.sale)
                            .map((product) => product.category?.name)
                        )
                      )
                        .filter(Boolean)
                        .map((categoryName, index) => (
                          <IonButton
                            key={index}
                            onClick={() => {
                              setIsActive(categoryName);
                              filter(categoryName);
                            }}
                            style={
                              isActive === categoryName
                                ? activeStyle
                                : inactiveStyle
                            }
                            class={
                              isActive === categoryName ? "active" : "inactive"
                            }
                          >
                            {categoryName}
                          </IonButton>
                        ))}
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonPopover
                    isOpen={showPopover}
                    onDidDismiss={() => {
                      setShowPopover(false);
                    }}
                  >
                    <ion-content class="ion-padding">
                      {i18n.t("items.successfullyAdded")}
                    </ion-content>
                  </IonPopover>

                  <IonPopover
                    isOpen={showPopover2}
                    onDidDismiss={() => {
                      setShowPopover2(false);
                    }}
                  >
                    <ion-content class="ion-padding">
                      {errorValidation}
                    </ion-content>
                  </IonPopover>

                  <IonPopover
                    isOpen={showPopover3}
                    onDidDismiss={() => {
                      setShowPopover3(false);
                      window.location.reload();
                    }}
                  >
                    <ion-content class="ion-padding">
                      {errorValidation}
                    </ion-content>
                  </IonPopover>
                  {noProduct && (
                    <IonCol style={{ marginTop: "20px" }}>
                      {i18n.t("items.noItems")} {`"${searchTerm}"`}
                    </IonCol>
                  )}

                  {isActive === "All" && (
                   <IonRow style={{ width: "100%" }}>
                      {Object.entries(
                        products
                          .filter((item) => !item.sale)
                          .filter((item) =>
                            item.name
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase())
                          )
                          .reduce((acc, item) => {
                            const categoryName = item.category.name;

                            if (!acc[categoryName]) {
                              // Initialize the category if not present
                              acc[categoryName] = [];
                            }

                            // Add the product to the category array
                            acc[categoryName].push(
                              <IonCol
                                key={item.id}
                                size="12"
                                sizeLg="4"
                                style={{ paddingLeft: "0px" }}
                              >
                                <IonCard
                                  onClick={() => getDetailedProduct(item.id)}
                                  style={{
                                    width: "95%",
                                    margin: "auto",
                                    marginTop: 10,
                                    padding: 20,
                                    cursor: "pointer",
                                    borderRadius: "6px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      height: "100%",
                                    }}
                                  >
                                    <IonRow>
                                      <IonCol
                                        size="12"
                                        sizeMd="12"
                                        className="ion-text-center"
                                      >
                                        <p className="itemName">{item.name}</p>
                                        {/* <p
    style={{
      textAlign: "center",
      color: "#303133",
    }}
  >
    {item.description}
  </p> */}
                                        {/* <IonRow className="ion-align-items-center">
                                          <IonCol
                                            size="6"
                                            sizeMd="6"
                                            className="ion-text-center priceFont"
                                          >
                                            {i18n.t("items.price")}
                                          </IonCol>
                                          <IonCol
                                            size="6"
                                            sizeMd="6"
                                            className="ion-text-center priceFont"
                                          >
                                            <u>{item.price}</u>
                                          </IonCol>
                                        </IonRow> */}
                                      </IonCol>

                                      <IonCol size="12" sizeMd="12">
                                        <IonRow
                                          className="ion-align-items-center"
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <IonCol size="3" sizeLg="3">
                                            <IonButton
                                              onClick={(event) => {
                                                event.stopPropagation();
                                                handleDecrement(item.id);
                                              }}
                                              fill="clear"
                                              size={"small"}
                                            >
                                              <IonIcon
                                                slot="icon-only"
                                                icon={removeCircle}
                                                style={{ color: "#980101" }}
                                              />
                                            </IonButton>
                                          </IonCol>
                                          <IonCol size="6" sizeLg="3">
                                            <IonItem style={{ width: "100%" }}>
                                              <IonInput
                                                style={{
                                                  width: "100%",
                                                  textAlign: "center",
                                                }}
                                                onClick={(event) => {
                                                  event.stopPropagation();
                                                }}
                                                type="text"
                                                value={counts[item.id]}
                                                onIonChange={(e) => {
                                                  let sanitizedValue =
                                                    e.detail.value
                                                      .replace(/[^0-9]/g, "")
                                                      .slice(0, 3);
                                                  if (sanitizedValue > 100) {
                                                    sanitizedValue =
                                                      sanitizedValue.slice(
                                                        0,
                                                        2
                                                      );
                                                  }
                                                  setCounts({
                                                    ...counts,
                                                    [item.id]: sanitizedValue,
                                                  });
                                                }}
                                              />
                                            </IonItem>
                                          </IonCol>
                                          <IonCol size="3" sizeLg="3">
                                            <IonButton
                                              onClick={(event) => {
                                                event.stopPropagation();
                                                handleIncrement(item.id);
                                              }}
                                              fill="clear"
                                              size={"small"}
                                              style={{ float: "right" }}
                                            >
                                              <IonIcon
                                                slot="icon-only"
                                                icon={addCircle}
                                                style={{ color: "#00650A" }}
                                              />
                                            </IonButton>
                                          </IonCol>
                                        </IonRow>
                                      </IonCol>
                                      <IonCol>
                                        <IonButton
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            handleAddCart(
                                              item.id,
                                              counts[item.id]
                                            );
                                          }}
                                          expand="full"
                                          style={{
                                            textTransform: "none",
                                            marginBottom: 10,
                                          }}
                                          class="addCartBtn"
                                        >
                                          {i18n.t("items.addToCart")}
                                        </IonButton>
                                      </IonCol>
                                    </IonRow>
                                  </div>
                                </IonCard>
                              </IonCol>
                            );

                            return acc;
                          }, {})
                      ).map(([categoryName, products]) => (
                        <div key={categoryName}>
                          <IonRow>
                            <IonCol size="12">
                              <p
                                style={{
                                  color: "#0083D0",
                                  fontSize: "28px",
                                  marginBottom: "0px",
                                }}
                              >
                                <u>{categoryName}</u>
                              </p>
                            </IonCol>
                          </IonRow>
                          <IonRow>{products}</IonRow>
                        </div>
                      ))}
                    </IonRow>
                  )}
                  {isActive !== "All" && (
                    <IonRow style={{ width: "100%" }}>
                      {productsFiltered
                        .filter((item) => {
                          if (searchTerm === "") {
                            return item.sale === false;
                          } else if (
                            item.name
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase())
                          ) {
                            return item.sale === false;
                          }
                        })
                        .map((item, index) => (
                          <IonCol
                            key={item.id}
                            size="12"
                            size-md="4"
                            style={{ marginBottom: "20px" }}
                          >
                            <IonCard
                              onClick={() => getDetailedProduct(item.id)}
                              style={{
                                width: "95%",
                                margin: "auto",
                                marginTop: 10,
                                padding: 20,
                                cursor: "pointer",
                                borderRadius: "6px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  height: "100%",
                                }}
                              >
                                <IonRow>
                                  <IonCol
                                    size="12"
                                    sizeMd="12"
                                    className="ion-text-center"
                                  >
                                    <p className="itemName">{item.name}</p>
                                    {/* <p
    style={{
      textAlign: "center",
      color: "#303133",
    }}
  >
    {item.description}
  </p> */}
                                    {/* <IonRow className="ion-align-items-center">
                                      <IonCol
                                        size="6"
                                        sizeMd="6"
                                        className="ion-text-center priceFont"
                                      >
                                        {i18n.t("items.price")}
                                      </IonCol>
                                      <IonCol
                                        size="6"
                                        sizeMd="6"
                                        className="ion-text-center priceFont"
                                      >
                                        <u>{item.price}</u>
                                      </IonCol>
                                    </IonRow> */}
                                  </IonCol>

                                  <IonCol size="12" sizeMd="12">
                                    <IonRow
                                      className="ion-align-items-center"
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <IonCol size="3" sizeLg="3">
                                        <IonButton
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            handleDecrement(item.id);
                                          }}
                                          fill="clear"
                                          size={
                                            isSmallScreen ? "small" : "default"
                                          }
                                        >
                                          <IonIcon
                                            slot="icon-only"
                                            icon={removeCircle}
                                            style={{ color: "#980101" }}
                                          />
                                        </IonButton>
                                      </IonCol>
                                      <IonCol size="6" sizeLg="3">
                                        <IonItem style={{ width: "100%" }}>
                                          <IonInput
                                            style={{
                                              width: "100%",
                                              textAlign: "center",
                                            }}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                            }}
                                            type="text"
                                            value={counts[item.id]}
                                            onIonChange={(e) => {
                                              let sanitizedValue =
                                                e.detail.value
                                                  .replace(/[^0-9]/g, "")
                                                  .slice(0, 3);
                                              if (sanitizedValue > 100) {
                                                sanitizedValue =
                                                  sanitizedValue.slice(0, 2);
                                              }
                                              setCounts({
                                                ...counts,
                                                [item.id]: sanitizedValue,
                                              });
                                            }}
                                          />
                                        </IonItem>
                                      </IonCol>
                                      <IonCol size="3" sizeLg="3">
                                        <IonButton
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            handleIncrement(item.id);
                                          }}
                                          fill="clear"
                                          size={
                                            isSmallScreen ? "small" : "default"
                                          }
                                          style={{ float: "right" }}
                                        >
                                          <IonIcon
                                            slot="icon-only"
                                            icon={addCircle}
                                            style={{ color: "#00650A" }}
                                          />
                                        </IonButton>
                                      </IonCol>
                                    </IonRow>
                                  </IonCol>
                                  <IonCol>
                                    <IonButton
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        handleAddCart(item.id, counts[item.id]);
                                      }}
                                      expand="full"
                                      style={{
                                        textTransform: "none",
                                        marginBottom: 10,
                                      }}
                                      class="addCartBtn"
                                    >
                                      {i18n.t("items.addToCart")}
                                    </IonButton>
                                  </IonCol>
                                </IonRow>
                              </div>
                            </IonCard>
                          </IonCol>
                        ))}
                    </IonRow>
                  )}
                </IonRow>
              </IonGrid>
            </IonContent>
          </IonPage>
        </div>
      </IonSplitPane>
    </>
  );
}

export default Items;
